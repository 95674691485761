import htmlParser from 'html-react-parser';
import React from 'react';

import styles from './Html.module.css';

interface Props {
  content: string;
}

export const Html = (props: Props) => {
  const { content } = props;

  return <div className={styles.html}>{htmlParser(content)}</div>;
};
